import { useEffect } from 'react';
import './App.scss';
import dotenv from  'dotenv'
import AuthService from './services/AuthService';



const App = () => {

  return (
    <div className="App">
    
    </div>
  );
}

export default App;
