import { useEffect, useState } from "react";
import logo from '../assets/home/kintec-logo-confirmation.png';
import customIcon from '../assets/home/custom-orthotics-master-icon.png';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import "../styles/styles.scss";
import { ApiService } from "../services/ApiService";
import moment from 'moment';


interface Booking {
    ID: number,
    BookingGUID: string,
    AppointmentDateTime: string,
    Comments: string,
    FirstName: string,
    LastName: string,
    Phone: string,
    MobilePhone: string,
    Email: string,
    IsBookingForChild: number,
    ContactFirstName: string,
    ContactLastName: string,
    AppointmentType: string,
    AppointmentSubtype: string,
    Location: string,
    AppointmentDate: string,
    AppointmentTime: string,
    Province: string
}

declare global {
    interface Window {
      dataLayer: Array<Record<string, any>>;
    }
}

const Confirmation = () => {
    const [apiService, setApiService] = useState<ApiService | null>(null);
    const [booking, setBooking] = useState<Booking | null>(null);
    const [duplicateAppt, setDuplicateAppt] = useState('');

    const clearSession = () => {
        window.sessionStorage.clear();
    }

    const fetchBooking = (localBookingID: string, remoteBookingID: string) => {

        const uri = "/api/booking/get-booking/" + localBookingID + "/" + remoteBookingID;
        apiService?.get(uri)
            .then(async response => {

                const string = await response.text();
                const data = JSON.parse(string);
                if (data.Success) {
                    const booking = data.Booking;
                    setBooking(booking);

                    // Google Tag Manager
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event':'booking_completed',
                        'product_type': booking.AppointmentType,
                        'location': booking.Location,
                        'province': booking.Province
                    });
                
                }

            })
    }

    const formatDate = (inputDate: string) => {
        const parsedDate = moment(inputDate);
        const formattedDate = parsedDate.format("dddd, MMMM Do, YYYY, h:mm A");
        return formattedDate;
    }

    useEffect(() => {

        const localBookingID = window.sessionStorage.getItem('localBookingID');
        const remoteBookingID = window.sessionStorage.getItem('remoteBookingID');
        const keepBooking = window.sessionStorage.getItem('keepBooking');
        const duplicateAppt = window.sessionStorage.getItem('duplicateAppt');

        const initialize = async () => {
            return ApiService.create();
        }

        if (apiService) {
            if (localBookingID && remoteBookingID) {
                fetchBooking(localBookingID, remoteBookingID);
            } else if (keepBooking && duplicateAppt) {
                setDuplicateAppt(formatDate(duplicateAppt));
            }

        } else {
            initialize().then(service => {
                setApiService(service);
            });
        }

    }, [apiService])

    return (
        <div id='confirmation'>
            <div id='confirmation-content'>
                <img src={logo} id='confirmation-logo' alt="Kintec Logo" />

                {booking &&

                    <div>

                        <Row>
                            <Col sm={5}>
                                <img src={customIcon} alt="Icon" />
                            </Col>
                            <Col sm={7}>
                                <h3>
                                    Thank you!<br />
                                    Your appointment is confirmed.
                                </h3>
                                <h4>We'll see you soon...</h4>
                                <p className='blurb'>Please check your inbox for a confirmation email with the details of your appointment.</p>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='appointment-details'>
                            <input name="booking-appointment-type" id="booking-appointment-type" value={booking?.AppointmentType} type="hidden" readOnly/>
                            <input name="booking-location-name" id="booking-location-name" value={booking?.Location} type="hidden" readOnly />
                            <input name="booking-province" id="booking-province" value={booking?.Province} type="hidden" readOnly />

                            <Col xs={5}>
                                <p className='bold'>Type:</p>
                                <p className='bold'>Location:</p>
                                <p className='bold'>Date:</p>
                                <p className='bold'>Time:</p>
                                <p className='bold'>Name:</p>
                                <p className='bold'>Email:</p>
                                <p className='bold'>Phone:</p>
                                <p className='bold'>Mobile:</p>
                                <p className='bold'>Comments:</p>
                            </Col>
                            <Col xs={7}>
                                <p>Orthotics {booking?.AppointmentType}</p>
                                <p>{booking?.Location}</p>
                                <p>{booking?.AppointmentDate}</p>
                                <p>{booking?.AppointmentTime}</p>
                                <p>{booking?.FirstName} {booking?.LastName}</p>
                                <p>{booking?.Email}</p>
                                <p>{booking?.Phone}</p>
                                <p>{booking?.MobilePhone || (<span>&nbsp;</span>)}</p>
                                <p>{booking?.Comments}</p>
                            </Col>
                        </Row>
                    </div>

                }

                {duplicateAppt &&

                    <Row>
                        <Col sm={5}>
                            <img src={customIcon} alt="Icon" />
                        </Col>
                        <Col sm={7}>

                            <h4>  Your {duplicateAppt} appointment will be kept. If you wish to book another appointment,
                                please call the clinic at 604-555-1234.</h4>
                        </Col>
                    </Row>
                }


                <hr />
                <div id='confirmation-footer'>
                    <a href='/' onClick={clearSession}>Book another appointment</a>
                    <a href='https://www.kintec.net/'>Return to homepage</a>
                    <a href='https://shop.kintec.net/'>Buy new gears</a>
                </div>
            </div>
        </div>
    );
};

export default Confirmation;
