import DetailsPanel from "../components/details-panel/DetailsPanel";
import BookingForm from "../components/form/BookingForm";

const KintecAnywhere = () => {
    const title = "Kintec Anywhere";
    const type = 'Virtual';

    const description = "Available for initial consultation or existing orthotic patients looking for a follow up assessment" + 
    ". Note: these appointments include a $95 assessment fee"

    const details = [
        '45-60 minute appointment', 
        'Review of activies, symptoms, and history',
        'X analysis & examinaion of your feet'
    ];

    const acknowledgements = [
        'I acknowledge that all of the information provided above is correct',
        'I acknowledge that there is a $95 non-refundable fee for an Assessment'
    ];

    return (
    <div className="content-container">
        
        <DetailsPanel title={title} description={description} details={details} />
        <div className="form-container">
        <div className="info-container">
            <span className="bold">Kintec</span> Anywhere assessments requires an Apple device with FaceID (such as an iPhone X,
            iPad Pro or newer version) is needed for the 3D foot scan used to make my custom orthotics. I also know
            that if I don't have such an Apple device, I can borrow from a friend!
        </div>
        <BookingForm title={title} type={type} acknowledgements={acknowledgements}/>

        </div>
    </div>);
};

export default KintecAnywhere;
