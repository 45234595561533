import React, { useState, useEffect } from "react";
import Modal from 'react-modal';

interface ErrorModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

// Modal style
const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    width: '300px',
    height: '150px',
    margin: 'auto',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '20px',
    backgroundColor: '#fff',
    outline: 'none'
  }
};

const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalStyle}
      contentLabel="Submission Error Modal"
    >
      <h2>Error</h2>
      <p>Sorry, an error has occurred</p>
      <button id="modal-close-btn" onClick={closeModal}>✕</button>
    </Modal>
  );
};


export default ErrorModal;