import { useNavigate } from "react-router-dom";
import DetailsPanel from "../components/details-panel/DetailsPanel";
import BookingForm from "../components/form/BookingForm";
import "../styles/styles.scss";
import {FaCheck} from "react-icons/fa";

const OrthoticAdjustment = () => {
    const navigate = useNavigate();

    const title = "Orthotic Adjustment";
    const type = 'Adjustment';

    const description = "Adjustment appointments are used when you have questions or concerns about your Kintec custom orthotic. Adjustment appointments are only available for patients within 12 months of their fitting. If you require an adjustment on orthotics that are older than 12 months, please book an Orthotic Assessment appointment type. ";

    const details = [
        'Existing Kintec orthotic patients only',
        'Adjustments are a default 30 minute appointment',
        'Most adjustments can be made on the spot. Some may required to be sent to our lab',
        'We understand the path to recovery is a process, which is why adjustments are FREE for customers who received their orthotics within the last 12 months'
    ];

    const acknowledgements = [
        'I acknowledge that all of the information provided above is correct'
    ];

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>): void => {

        if (e.target.value === 'assessment') {
            navigate('/booking/orthotic-assessment')
        } 

    }

    return (
    <div className="content-container">
        <DetailsPanel title={title} description={description} details={details} />
        <div className="form-container">
            <div className="info-container choose-orth" onChange={onChangeValue}>
                <label htmlFor="orth-assessment" className="radio-label">
                    <input className="radio-input" id="orth-assessment" type="radio" value="assessment" name="type" />
                    <span className="custom-radio"><FaCheck className='font-awesome-check'/></span>
                    Orthotic Assessment
                </label>

                <label htmlFor="orth-adjustment" className="radio-label">
                    <input className="radio-input" id="orth-adjustment" type="radio" value="adjustment" name="type" defaultChecked/>
                    <span className="custom-radio"><FaCheck className='font-awesome-check'/></span>
                    Orthotic Adjustment
                </label>
            </div>
            <BookingForm title={title} type={type} acknowledgements={acknowledgements}/>
        </div>
    </div>
    )

};

export default OrthoticAdjustment;
