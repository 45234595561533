import DetailsPanel from "../components/details-panel/DetailsPanel";
import BookingForm from "../components/form/BookingForm";

const Bracing = () => {
    const title = "Bracing";

    const description = "Whether it’s knee osteoarthritis, ligament injury or patellofemoral (kneecap) " +
        "pain, Kintec’s bracing appointment can help stabilize your affected joint, reduce pain and " +
        "promote recovery. We carry a selection of custom and off-the-shelf options to suit your " +
        "needs and lifestyle.";

    const details = [
        'FREE 30-minute appointment', 
        'Review of activities, symptoms, and history',
        'Fitting from a Brace Expert'
    ];

    const acknowledgements = [
        'I acknowledge this appointment is for non-custom braces only and that if I require a <strong> custom brace </strong> to call my <a style="color: #FFB68D" target="blank" href="https://www.kintec.net/locations"> nearest location </a>',
        'I acknowledge that all of the information provided above is correct'
    ];

    const additionalText = '<p> Looking for a custom or unloading brace? Call your nearest location to <a href="https://www.kintec.net/locations/"> book </a> </p>'

    return (
    <div className="content-container">
        <DetailsPanel title={title} description={description} details={details} additionalText={additionalText}/>
        <div className="form-container">
            <BookingForm title={title} type={title} acknowledgements={acknowledgements}/>
        </div>
    </div>);
};

export default Bracing;
