import AuthService from "./AuthService";
import { UtilitiesService } from "./UtilitiesService";


export class ApiService {
    private baseUrl: string;
    private token: string | null = null;


    constructor() {
        this.baseUrl = UtilitiesService.getApiUrl();
    }

    static async create() {
        const service = new ApiService();
        await service.initialize();
        return service;
     }
    

    async initialize() {
        const token = await AuthService.getToken;
        console.log(token);
        this.token = token;
    }

    public get(uri: string):Promise<Response>  {
        const url = this.baseUrl + uri;

        const headers = { 
            'Authorization': 'Bearer ' + this.token,   
        };

        const requestInit = {
            credentials: 'include',
            headers: headers,
            mode: "cors",
            cache: "no-cache"
        }

        return fetch(url, requestInit as RequestInit);
    }

    public post(uri: string, data: any):Promise<Response> {
        const headers = { 
            'Authorization': 'Bearer ' + this.token,   
            'Content-Type': 'application/json'
        };
        
        const requestOptions = {
            credentials: 'include',
            headers: headers,
            mode: "cors",
            method: 'POST',
            body: JSON.stringify(data)
        }

        const url = this.baseUrl + uri;

        return fetch(url, requestOptions as RequestInit)
    }

    public clearToken() {
        this.token = null;
    }

    public getToken() {
        return this.token;
    }

}

