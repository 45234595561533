import { UtilitiesService } from "./UtilitiesService";

const requestInit = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
}

const AuthService = {

    getToken: new Promise<string>((resolve, reject) => {

        if (window.sessionStorage.getItem('tokenObj')) {
            const tokenJson = window.sessionStorage.getItem('tokenObj');

            if (tokenJson) {
                const tokenObj = JSON.parse(tokenJson);
                let expiredAt = new Date(tokenObj.expiry);

                if (expiredAt > new Date()) { // Validate expiry date.
                    console.log('using stored token');
                    console.log(tokenJson);

                    resolve(tokenObj.token);
                    return;
                } else {
                    console.log('token was expired');
                    window.sessionStorage.clear();

                }

            }
        }


        const baseUrl = UtilitiesService.getApiUrl();
        const url = baseUrl + "/api/booking/get-token";

        fetch(url, requestInit as RequestInit)
            .then(async response => {
                const string = await response.text();

                if (string !== "") {
                    const json = JSON.parse(string);
                    const token = json.token;
                    window.sessionStorage.setItem('tokenObj', string)


                    console.log('using new token');
                    console.log(string);
                    resolve(token)
                }

            });
    })
};

export default AuthService;