import "./DetailsPanel.scss";

const DetailsPanel = ({ title, description, details, additionalText }: { title: string, description: string, details: string[], additionalText?: string}) => {


    let assetPath = '';

    switch(title) {
        case 'Kintec Anywhere':
            assetPath = require("../../assets/booking/anywhere-booking.png");
            break;
        default:
            let fileName = title.replace(/\s+/g, '-').toLowerCase() + "-booking.png";
            assetPath = require("../../assets/booking/" + fileName);
            break
    }


    return (
    <div className="details-container centerImage">
        <img src={assetPath}/>

        <div className='sidebar-content'>
            <h2 className="title">{title} <br/> Appointment</h2>
            {description ? <p>{description}</p> : ''}
            <ul>
                {details.map((detail, i) => {
                    return (<li key={i}>{detail}</li>)
                })}
            </ul>

            {additionalText && <div dangerouslySetInnerHTML={{ __html: additionalText }} />}

            <div className="questions-container">
                <p>Any questions? See our <a href='/'>FAQ page</a> or call <a href='tel:1-877-441-3540'>1-877-441-3540</a> and speak with a Fitting Expert.</p>
            </div>
        </div>
    </div>);
};

export default DetailsPanel;