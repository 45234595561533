import DetailsPanel from "../components/details-panel/DetailsPanel";
import BookingForm from "../components/form/BookingForm";

const Compression = () => {
    const title = "Compression";

    const description = "Compression socks work by providing graduated compression that is highest at the feet and ankles that tapers towards the calf. They come in a range of lengths from knee to thigh-high, and there are even maternity options. Your compression appointment includes:";

    const details = [
        'FREE 30-minute review of your activities, lifestyle needs and relevant medical history',
        'Review of available styles, lengths, and compression options from top brands like Bauerfeind and Sigvaris',
        'Fitting from Certified Compression Fitting'
    ];

    const acknowledgements = [
        'I acknowledge that all of the information provided above is correct'
    ];

    return (
    <div className="content-container">
        <DetailsPanel title={title} description={description} details={details} />
        <div className="form-container">
            <BookingForm title={title} type={title} acknowledgements={acknowledgements}/>
        </div>
    </div>);
};

export default Compression;
