export class UtilitiesService {
    static PROD_API_URL = 'https://booking-api.kintec.net';
    static DEV_API_URL = 'https://dev-booking-api.kintec.net';

    static inProduction() {
        const currentDomain = window.location.hostname;
        return currentDomain === 'booking.kintec.net' || currentDomain === 'booking-app-s3.kintec.net';
    }

    static getApiUrl() {
        return this.inProduction() ? UtilitiesService.PROD_API_URL : UtilitiesService.DEV_API_URL;

    }
}