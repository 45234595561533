import DetailsPanel from "../components/details-panel/DetailsPanel";
import BookingForm from "../components/form/BookingForm";

const Footwear = () => {
    const title = "Footwear";

    const description = "A Kintec Fit Expert will provide the highest level of personalized service to address your footwear needs, including:";

    const details = [
        'Review of your activity goals and any aches or pains',
        'Gait analysis and touchless 3D Fitscan™',
        'Recommendations from our curated inventory of athletic and lifestyle footwear',
        'Test-drive your footwear on our in-house treadmill to ensure you FEEL your perfect fit.'
    ];

    const acknowledgements = [
        'I acknowledge that all of the information provided above is correct'
    ];

    return (
    <div className="content-container">
        <DetailsPanel title={title} description={description} details={details} />
        <div className="form-container">
            <BookingForm title={title} type={title} acknowledgements={acknowledgements}/>
        </div>
    </div>);
};

export default Footwear;
