import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import "./Modals.scss";
import moment from 'moment';


interface DuplicateModalProps {
  isOpen: boolean;
  duplicateAppt: string;
  currentAppt: string;
  apptType: string;
  moveAppt: () => void;
  closeModal: () => void;
  buttonPressed: boolean;
}

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    width: '550px',
    height: '325px',
    margin: 'auto',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '60px 40px',
    backgroundColor: '#fff',
    outline: 'none',
    overflow: 'hidden'
  }
};

const DuplicateModal: React.FC<DuplicateModalProps> = ({ isOpen, duplicateAppt, currentAppt, apptType, moveAppt, closeModal, buttonPressed }) => {

  const formatDate = (inputDate: string) => {
    const parsedDate = moment(inputDate);
    const formattedDate = parsedDate.format("dddd, MMMM Do, YYYY [at] h:mm A");
    return formattedDate;
  }

  const declineMoveAppt = () => {
    window.sessionStorage.setItem('bookingConfirmed', '1');
    window.sessionStorage.setItem('keepBooking', '1');
    window.sessionStorage.setItem('duplicateAppt', duplicateAppt);

    const path = '/confirmation/' + apptType;
    window.location.href = path;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalStyle}
      contentLabel="Duplicate Appointment Modal"
    >
      <p>
        It looks like you already have an appointment booked for
        <strong> {formatDate(duplicateAppt)}</strong>
      </p>

      <p>
        Would you like to move the existing appointment to the new date and time at
        <strong> {formatDate(currentAppt)}</strong>?
      </p>

      <div className="btn-container">
        <button className="btn btn-default" onClick={declineMoveAppt}>No</button>
        <button className="btn btn-primary" onClick={moveAppt} disabled={buttonPressed}>Yes</button>
      </div>
      <button id="modal-close-btn" onClick={closeModal}>✕</button>
    </Modal>
  );
};


export default DuplicateModal;