import { Outlet } from "react-router-dom";
import SidebarNavigation from "../sidebar/Sidebar";
import "../../styles/styles.scss";

const AppLayout = () => {
    return (
        <div id="content-wrapper">
            <div className="header">
                <a href="/"><img src={require("../../assets/booking/back-button-booking.png")} alt="Back Button"/></a>
                <img className="logo" src={require("../../assets/booking/kintec-logo-booking.png")} alt="Kintec Logo"/>
            </div>
            <SidebarNavigation />
            <Outlet />
        </div>);
};

export default AppLayout;